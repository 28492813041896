.order-card {
    width: 100%;
}

.delivery {
    margin-bottom: 20px;
    margin-left: 20px;
}

.order-form-header {
    margin-top: 40px;
    margin-bottom: 5px;
}

.order-form-item {
    margin-bottom: 0 !important;
}

.order-form-value {
    font-weight: 600;
}

.order-form-loading {
    background: var(--primary-color);
    width: fit-content;
    padding: 0 4px;
    font-weight: 600;
}

.order-form-awb {
    background: #efefef;
    width: fit-content;
    padding: 0 4px;
    font-weight: 600;
}

.transit-warehouse {
    background: var(--primary-color);
    width: fit-content;
    padding: 0 4px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 5px;
}

.received {
    font-size: 24px;
    color: #389e0d;
    margin-bottom: 20px;
}


