.not-support {
    background: radial-gradient(circle, rgba(51, 116, 89, 1) 0%, rgba(30, 81, 60, 1) 34%, rgba(11, 29, 22, 1) 100%);
    height: 100vh;
    align-items: center;
    text-align: center;
}

.not-support-title {
    padding: 40px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;

    color: var(--primary-color);
}

.not-support-text {
    text-align: center;
    font-size: 20px;
    padding: 0 20px 15px;
    color: #ffffff;
}

.not-support-url {
    font-weight: 700;
    color: var(--primary-color);
}
